<template>
  <div>
    <SpinnerLoader v-if="clientsAdvertisersStatus !== 'success'" :loading="clientsAdvertisersStatus" />
    <template v-else-if="clientsAdvertisersStatus === 'success' && clientsAdvertisers">
      <virtual-scroll :items="clientsAdvertisers.items" :item-height="27" class="vscroll table-responsive mb-0 clients-advertisers-wrapper">
        <template slot-scope="{ items }">
          <table class="table table-sm small table-hover table-clients-advertisers table-bordered ws-nowrap sticky-headers sticky-footer">
            <thead>
              <tr>
                <th>
                  <div class="resizable agency">{{ $t('table.agency') }}</div>
                </th>
                <th>
                  <div class="resizable project">{{ $t('table.project') }}</div>
                </th>
                <th>
                  <div class="resizable order">{{ $t('table.order') }}</div>
                </th>
                <th>
                  <div class="resizable media_plan">{{ $t('table.mediaPlan') }}</div>
                </th>
                <th>
                  <div class="resizable advertiser">{{ $t('table.advertiser') }}</div>
                </th>
                <th>
                  <div class="resizable brand">{{ $t('table.brand') }}</div>
                </th>
                <th>
                  <div class="resizable channel">{{ $t('channelModal.channel') }}</div>
                </th>
                <th>{{ $t('channelModal.from') }}</th>
                <th>{{ $t('channelModal.to') }}</th>
                <th class="text-right">{{ $t('table.placementCount') }}</th>
                <th class="text-right">{{ $t('table.secCount') }}</th>
                <th class="text-right" style="min-width: 60px">{{ !showEquals ? 'wGRP' : $t('equalsWGRP.equals') }}</th>
                <th class="text-right">{{ $t('table.discount') }}(-)/<br />{{ $t('table.surcharge') }}(+)</th>
                <th class="text-right" style="min-width: 90px">{{ $t('table.price') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.mediaplan_id">
                <td name="agency">{{ item.agency_name }}</td>
                <td name="project">{{ item.project_name }}</td>
                <td name="order">{{ item.order_name }}</td>
                <td name="media_plan">{{ item.mediaplan_name }}</td>
                <td name="advertiser">{{ item.advertiser_name }}</td>
                <td name="brand">{{ item.brand_name }}</td>
                <td name="channel">{{ item.channel_name }}</td>
                <td class="ws-nowrap">{{ item.date_from | convertDate }}</td>
                <td class="ws-nowrap">{{ item.date_to | convertDate }}</td>
                <td class="text-right">{{ item.spot_count }}</td>
                <td class="text-right">{{ item.seconds_total }}</td>
                <td class="text-right">{{ item.wgrp | toFixedAndSpace }}</td>
                <td class="ws-nowrap text-right">{{ item.total_discount ? (+item.total_discount).toFixed(2) + '%' : '' }}</td>
                <td class="text-right">{{ item.price | toFixedAndSpace }}</td>
              </tr>
            </tbody>
            <tfoot v-if="clientsAdvertisers.totals" class="font-weight-bold">
              <tr>
                <td name="period" colspan="9">{{ $t('booking.total') }}</td>
                <td class="text-right">{{ clientsAdvertisers.totals.spots }}</td>
                <td class="text-right">{{ clientsAdvertisers.totals.seconds }}</td>
                <td class="text-right">{{ clientsAdvertisers.totals.wgrp | toFixedAndSpace }}</td>
                <td class="ws-nowrap text-right">{{ clientsAdvertisers.totals.discount ? (+clientsAdvertisers.totals.discount).toFixed(2) + '%' : '' }}</td>
                <td class="text-right">{{ clientsAdvertisers.totals.price | toFixedAndSpace }}</td>
              </tr>
            </tfoot>
          </table>
        </template>
      </virtual-scroll>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import convertDate from '@/filters/convertDate';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import VirtualScroll from '@/components/VirtualScroll';

export default {
  name: 'ClientAdvertisers',
  components: { SpinnerLoader, VirtualScroll },
  filters: { convertDate, toFixedAndSpace },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      clientsAdvertisers: 'getReportClientsAdvertisers',
      clientsAdvertisersStatus: 'getReportClientsAdvertisersStatus',
      sh_settings: 'getSalehousesSettings',
    }),
    showEquals() {
      return this.FEATURES.EQUALS && this.sh_settings[0]?.is_equals_enabled;
    },
  },
  destroyed() {
    this.$store.commit('clearReportClientsAdvertisers');
  },
  methods: {},
};
</script>

<style lang="sass">
.reports-page div.table-responsive.clients-advertisers-wrapper
  height: calc(100vh - 210px)

table.table-clients-advertisers
  td, th
    white-space: nowrap

  div.resizable
    &.channel,
    &.agency,
    &.advertiser,
    &.brand,
    &.status,
    &.type,
    &.target
      min-width: 70px
      width: 70px
    &.project,
    &.order,
    &.media_plan
      min-width: 100px
      width: 100px

  td[name="channel"],
  td[name="agency"],
  td[name="advertiser"],
  td[name="brand"],
  td[name="status"],
  td[name="type"],
  td[name="target"]
    max-width: 70px
    min-width: 70px
  td[name="project"],
  td[name="order"],
  td[name="media_plan"]
    max-width: 100px
    min-width: 100px

@media (min-width: 1771px)
  .reports-page div.table-responsive.clients-advertisers-wrapper
    height: calc(100vh - 154px)
</style>
